import { FunctionComponent } from 'react';

const OurCustomers: FunctionComponent<{}> = () => {
  return (
    <section id="portfolio" className="clearfix">
      <div className="container">
        <header className="section-header">
          <h3 className="section-title">Customers</h3>
        </header>
        <div className="row">
          {/* <div className="col-12 col-md-6 col-lg-4 p-2">
            <div className="card shadow-sm shadow-sm p-4">
              <div style={{ height: '2.5rem' }}>
                <img
                  src="img/customers/talon.webp"
                  style={{
                    objectFit: 'contain',
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                  alt=""
                />
              </div>
              <div>
                <h4
                  className="mb-0 mt-4"
                  style={{ fontWeight: 600, color: '#aaaaaa' }}
                >
                  Talon
                </h4>
                <p className="mb-0 text-muted" style={{ color: '#aaaaaa' }}>
                  Security for the future of Work
                </p>
              </div>
            </div>
          </div> */}
          <div className="col-12 col-md-6 col-lg-4 p-2">
            <div className="card shadow-sm shadow-sm p-4">
              <div style={{ height: '2.5rem' }}>
                <img src="img/customers/accessibe.svg" alt="" />
              </div>
              <div>
                <h4 className="mb-0 mt-4" style={{ fontWeight: 600 }}>
                  accessiBe
                </h4>
                <p className="mb-0 text-muted" style={{ color: '#aaaaaa' }}>
                  Web accessibility solutions
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 p-2">
            <div className="card shadow-sm p-4">
              <div style={{ height: '2.5rem' }}>
                <img src="img/customers/strigo.svg" alt="" />
              </div>
              <div>
                <h4 className="mb-0 mt-4" style={{ fontWeight: 600 }}>
                  Strigo
                </h4>
                <p className="mb-0 text-muted">
                  The future of customer education
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 p-2">
            <div className="card shadow-sm p-4">
              <div style={{ height: '2.5rem' }}>
                <img src="img/customers/cyberillium.png" alt="" />
              </div>
              <div>
                <h4 className="mb-0 mt-4" style={{ fontWeight: 600 }}>
                  Cyberillium
                </h4>
                <p className="mb-0 text-muted">
                  Security issues investigation platform
                </p>
              </div>
            </div>
          </div>
          <div className="col-0 col-lg-2" />
          <div className="col-12 col-md-6 col-lg-4 p-2">
            <div className="card shadow-sm p-4">
              <div style={{ height: '2.5rem' }}>
                <img src="img/customers/rhino.svg" alt="" />
              </div>
              <div>
                <h4 className="mb-0 mt-4" style={{ fontWeight: 600 }}>
                  Rhino Eco
                </h4>
                <p className="mb-0 text-muted">
                  Making solar energy accessible
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 p-2">
            <div className="card shadow-sm p-4">
              <div style={{ height: '2.5rem' }}>
                <img src="img/customers/pickapier.svg" alt="" />
              </div>
              <div>
                <h4 className="mb-0 mt-4" style={{ fontWeight: 600 }}>
                  Pick a Pier
                </h4>
                <p className="mb-0 text-muted">
                  We connect boaters and marinas
                </p>
              </div>
            </div>
          </div>
          <div className="col-0 col-lg-2" />
          <p className="col-12 mt-4 mb-0 p-0 text-muted text-center text-small">
            ... and more
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurCustomers;

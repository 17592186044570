import { FunctionComponent, useState } from 'react';
import { FirestoreMutation } from '@react-firebase/firestore';

const InnerForm: FunctionComponent<{
  onContactEmailSubmit: ({
    name,
    email,
    subject,
    message,
    setSent,
  }: {
    name: string;
    email: string;
    subject: string;
    message: string;
    setSent: React.Dispatch<React.SetStateAction<boolean>>;
  }) => Promise<void>;
}> = ({ onContactEmailSubmit }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);

  return (
    <section id="contact" className="section-bg">
      <div className="container-fluid">
        <div className="section-header">
          <h3>Contact Us</h3>
        </div>
        <div className="row wow">
          {/* <div className="col-lg-6">
            <div className="map mb-4 mb-lg-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.7354726524954!2d34.74881371542806!3d31.99518568121511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502b3a219102ca7%3A0x79cba56064d6eb47!2sMoshe+Sharett+St+6%2C+Rishon+LeTsiyon!5e0!3m2!1sen!2sil!4v1566386294125!5m2!1sen!2sil"
                frameBorder={0}
                style={{ border: 0, width: '100%', height: '333px' }}
                allowFullScreen
                title="offices-map"
              />
            </div>
          </div> */}
          <div className="col-lg-3" />
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-4 info">
                <i className="ion-ios-email-outline" />
                <p className="m-0">felix@innovateam.co</p>
                <i className="ion-ios-email-outline" />
                <p>dvir@innovateam.co</p>
              </div>
              <div className="col-md-3 info">
                <i className="ion-ios-telephone-outline" />
                <p className="m-0">+972544710690</p>
                <i className="ion-ios-telephone-outline" />
                <p>+972547271228</p>
              </div>
              <div className="col-md-5 info">
                <i className="ion-ios-location-outline" />
                <p>P.B. 80, Neve Eitan, IL 1084000</p>
              </div>
            </div>
            <div className="form">
              {sent ? (
                <h4 className="show text-center pt-4">
                  Your message has been sent.
                  <br />
                  We will contact you very soon. Thank you!
                </h4>
              ) : (
                <form method="post" className="contactForm">
                  <div className="form-row">
                    <div className="form-group col-lg-6">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        onChange={(evt) => setName(evt.target.value)}
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        onChange={(evt) => setEmail(evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      id="subject"
                      placeholder="Subject"
                      onChange={(evt) => setSubject(evt.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      className="form-control"
                      id="message"
                      placeholder="Message"
                      rows={5}
                      onChange={(evt) => setMessage(evt.target.value)}
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      title="Send Message"
                      onClick={(evt) => {
                        evt.preventDefault();
                        onContactEmailSubmit({
                          name,
                          email,
                          subject,
                          message,
                          setSent,
                        });
                      }}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className="col-lg-3" />
        </div>
      </div>
    </section>
  );
};

const ContactForm: FunctionComponent<{}> = () => {
  return (
    <FirestoreMutation type="add" path={`/contacts`}>
      {({ runMutation }) => {
        const onContactEmailSubmit = async ({
          name,
          email,
          subject,
          message,
          setSent,
        }: {
          name: string;
          email: string;
          subject: string;
          message: string;
          setSent: React.Dispatch<React.SetStateAction<boolean>>;
        }) => {
          if (name && email && subject && message) {
            try {
              await runMutation({
                to: ['felix@innovateam.co', 'dvir@innovateam.co'],
                message: {
                  subject: `Innovateam contact: ${name} (${email})`,
                  text: `Contact named ${name} (${email}) has left a message on Innovateam website.\n\n${subject}\n\n${message}`,
                },
              });
              setSent(true);
            } catch (err) {
              console.error('Failed to save contact', err);
              setSent(false);
            }
          }
        };
        return <InnerForm onContactEmailSubmit={onContactEmailSubmit} />;
      }}
    </FirestoreMutation>
  );
};

export default ContactForm;

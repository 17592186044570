import firebase from 'firebase/app';
import 'firebase/auth';

// Firebase Config
export const firebaseConfig = {
  apiKey: 'AIzaSyAulm82NQjGG3uv_0SWxpmKmiZA404hp3s',
  authDomain: 'innovateam-1df1c.firebaseapp.com',
  databaseURL: 'https://innovateam-1df1c.firebaseio.com',
  projectId: 'innovateam-1df1c',
  storageBucket: 'innovateam-1df1c.appspot.com',
  messagingSenderId: '946850711780',
  appId: '1:946850711780:web:aa87b7d583901b23',
};

export default firebase.initializeApp(firebaseConfig);

import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase';
import { FirestoreProvider } from '@react-firebase/firestore';

import { firebaseConfig } from './services/firebase';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <FirestoreProvider firebase={firebase} {...firebaseConfig}>
      <App />
    </FirestoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
